import axios from 'axios'

export default{
  namespaced: true,

  state: {
    token:null,
    user:null,
  },
  getters:{
        authenticated(state) {
            return state.token && state.user
        },
        user (state){
            return state.user
        }
  },
  mutations: {
    SET_TOKEN (state,token){
        state.token = token
    },
    SET_USER (state,data){
        state.user = data
    }
  },
  actions: {
    async signIn({dispatch},credentials) {
        const response = await axios.post('auth/login',credentials)
        return dispatch('attempt',response.data.access_token)
    },
    async attempt ({commit,state},token){

        if(token){
            commit('SET_TOKEN',token)
        }
        if(!state.token){
           return
        }
        try{
        const response = await axios.get("auth/user")
            if(response.data.type!="Admin"){
              alert("UnAuthorized")
              commit('SET_TOKEN',null)
              commit('SET_USER',null)  
              return
            }
           commit('SET_USER',response.data)
        }catch(e){
        commit('SET_TOKEN',null)
        commit('SET_USER',null)  
          }       
    },
    Logout({commit}){
        return axios.get('auth/logout').then(() =>{
            commit('SET_TOKEN',null)
            commit('SET_USER',null)
        })
    }
  },
}
