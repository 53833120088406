import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const Login = () => 
import(/* webpackChunkName: "chlogin" */'../views/Login.vue')
const AllStores = () => 
import(/* webpackChunkName: "challstores" */'../views/AllStores.vue')
const SingleStore = () => 
import(/* webpackChunkName: "chstore" */'../views/SingleStore.vue')
const MapView = () => 
import(/* webpackChunkName: "chmap" */'../views/MapView.vue')


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/AllStores',
    name: 'AllStores',
    component: AllStores,
    beforeEnter:(to,from,next) => {
      if(!store.getters['auth/authenticated']){
        return next({
          name: 'Login',
        })
      }
      next()
    }
  },
  {
    path: '/MapView',
    name: 'MapView',
    component: MapView,
    beforeEnter:(to,from,next) => {
      if(!store.getters['auth/authenticated']){
        return next({
          name: 'Login',
        })
      }
      next()
    }
  },
  {
    path: '/SingleStore/:storeID',
    name: 'SingleStore',
    component: SingleStore,
    beforeEnter:(to,from,next) => {
      if(!store.getters['auth/authenticated']){
        return next({
          name: 'Login',
        })
      }
      next()
    }
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
