import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  en: {
    languageselect: "English",

    navbarAllStore: "Stores",
    navbarMap: "Map",
    navbarLogout: "Logout",
    navbarBack: "Back",

    loginTitle: "Sign in as an Admin",
    loginEmail: "Email",
    loginPassword: "Password",
    loginBtn: "Login",
    //errors
    loginEmailError: "Enter Your Correct Email",
    loginUnauthorized: "The Account is not Authorized to Enter This Website",
    loginWrongEmail: "Please write your email correctly",
    loginUnAuth: "Wrong Password Or Email",

    mainLocationAlert: "Allow Location access to proceed",
    mainUserName: "Sales Representer Name",
    mainStoreType: "Store Type",
    mainStoreOwnerName: "Store Owner Name",
    mainStoreName: "Store Name",
    mainPhoneNumber: "Phone Number",
    mainPhoneNumber2: "Second Phone Number (Optional)",
    mainCity: "City",
    mainAllCity: "All Cities",
    mainNone: "Empty",
    mainTableEmpty: "No Data",
    mainEmptyFields: "Search Fields Are Empty",

    mainStatus: "Store Status",
    mainDescriptin: "Description",
    mainDate: "Schedule a date",
    mainAccNum: "Account Number",
    mainRejectionReason: "Reason of Rejection",
    mainIsBankUser: "Is a bank user?",

    mainPhoto: "Store Image",
    imageName: "Click Here",
    mainBtn: "Submit",
    updateBtn: "Update",
    cancelBtn: "Cancel",
    okBtn: "Ok",

    //errors
    mainStoreTypeError: "Enter Store Type",
    mainStoreOwnerNameError: "Enter Store Owner Name",
    mainStoreNameError: "Enter Store Name",
    mainPhoneNumberError: "Enter Phone Number",
    mainPhoneNumber2Error: "Second Phone Number (Optional)",
    mainCityError: "Select City",
    mainStatusError: "Select Store Status",
    mainDescriptinError: "Enter Description",
    mainPhotoError: "Store Image",
    alertLocationِError:
      "An Error Occourd While Tyring to Get Your Location, Please Try Again.",
    alertEnterAllFields: "Enter All Fields",
    alertSeccesful: "Successfully Added",
    alertUpdate: "Updated",
    alertNoChange: "Nothing Changed",

    alertLocationِBrowserError:
      "Your Broswer Doesn't support location services",
    alertFileType: "Invalid File Type",
    alertPhone: "Invalid Phone Number",
    alertError: "Error Occurred",

    createdat: "Created At",
    coordinates: "Coordinates",
    actions: "Actions",
    editBtn: "Edit",
  },
  ar: {
    languageselect: "العربية",

    navbarAllStore: "المتاجر",
    navbarMap: "الخارطة",
    navbarLogout: "تسجيل الخروج",
    navbarBack: "الرجوع",

    loginTitle: "تسجيل دخول كمسؤول",
    loginEmail: "البريد الالكتروني ",
    loginPassword: "الرمز السري",
    loginBtn: "دخول",
    //errors
    loginEmailError: "يرجى كتابة البريد الالكتروني بشكل صحيح",
    loginUnauthorized: "الحساب غير مصرح بالدخول",
    loginWrongEmail: "يرجى كتابة البريد الاللكتروني بشكل صحيح",
    loginUnAuth: "الرمز السري او البريد الالكتروني خاطئ",

    mainLocationAlert: "اقبل الوصول الى الموقع لاكمال عملية التسجيل",
    mainUserName: "اسم المندوب",

    mainStoreType: "نوع المتجر",
    mainStoreOwnerName: "اسم صاحب المتجر",
    mainStoreName: "اسم المتجر",
    mainPhoneNumber: "رقم الهاتف",
    mainPhoneNumber2: "رقم الهاتف الثاني (اختياري)",
    mainCity: "المحافظة",
    mainAllCity: "جميع المحافظات",
    mainNone: "لا يوجد",
    mainTableEmpty: "لا توجد بيانات",
    mainEmptyFields: "حقول البحث فارغة",
    mainStatus: "حالة المتجر",
    mainDescriptin: "المتطلبات",
    mainDate: "تحديد موعد",
    mainAccNum: "رقم الحساب البنكي",
    mainRejectionReason: "سبب الرفض",
    mainIsBankUser: "يمتلك حساب في البنك؟",

    mainPhoto: "صورة المتجر",
    imageName: "اضغط هنا",

    mainBtn: "ارسال",
    updateBtn: "تحديث",
    cancelBtn: "الغاء",
    okBtn: "تم",

    //errors
    mainStoreTypeError: "يرجى اختيار نوع المتجر",
    mainStoreOwnerNameError: "يرجى ادخال اسم صاحب المتجر",
    mainStoreNameError: "يرجى ادخال اسم المتجر",
    mainPhoneNumberError: "يرجى ادخال رقم الهاتف الخاص بصاحب المتجر",
    mainCityError: "يرجى اختيار المحافظة",
    mainStatusError: "يرجى اختيار حالة المتجر",
    mainDescriptinError: "يرجى كتابه المتطلبات",
    mainPhotoError: "يرجى اختيار صورة للمتجر",
    alertLocationِError:
      "حصل خطأ في الحصول على موقع المتجر يرجى المحاولة من جديد",
    alertEnterAllFields: "يرجى ادخال جميع الحقول",
    alertSeccesful: "تم الاضافة بنجاح",
    alertUpdate: "تم التحديث",

    alertLocationِBrowserError: "المتصفح لا يدعم خدمة المواقع",
    alertFileType: "صيغة الملف غير مدعومة",
    alertPhone: "رقم الهاتف غير صحيح",
    alertError: "حصل خطأ في عملية التسجيل",
    alertNoChange: "لم يتغير شيء",

    createdat: "تاريخ الاضافة",
    coordinates: "الاحداثيات",
    actions: "الاجرائات",
    editBtn: "تعديل",
  },
};
export default new VueI18n({
  locale: "ar",
  messages,
});
